<template>
    <component :is="tag">
        <slot :targetUrl="targetUrl">
            <WtcImage v-if="targetUrl" :url="targetUrl"></WtcImage>
            <span v-if="natCode" class="nat-code">{{ natCode }}</span>
        </slot>
    </component>
</template>

<script setup lang="ts">
import { computed } from "vue";

import WtcImage from "./WtcImage.vue";

const props = defineProps({
    flagCode: String,
    flagPublicPath: {
        type: String,
        default: "flags/"
    },
    natCode: String,
    sourceUrl: String,
    fileExtension: {
        type: String,
        default: "png"
    },
    tag: {
        type: String,
        default: "div"
    }
});

const natUrl = computed((): string => {
    const { flagCode, natCode, fileExtension } = props;
    if (flagCode || natCode) {
        return `${props.flagPublicPath}${flagCode ?? natCode}.${fileExtension}`;
    }
    return "";
});

const targetUrl = computed((): string => (props.sourceUrl ?? natUrl.value).toLowerCase());
</script>
