import { useDateFormat } from "@vueuse/core";
export type CommonTimeTypes =
    | "HH:mm" // 14:12
    | "DD.MM HH:mm" // 01.01 14:12
    | "DD.MM.YYYY" // 01.01.2024
    | "DD.MM.YYYY, HH:mm" // 01.01.2024 14:12
    | "DD/MM" // 01.01
    | "dddd, DD. MMMM"
    | "DD. MMM YYYY"
    | "DD. MMMM YYYY"; // 01. MAR 2024
import { computed, ref } from "vue";
import { Ref } from "vue";
export type CommonLocalisationArgument = "de" | "en" | "fr" | "no" | "sv" | "it" | "nl" | "po";
export const TIME_ZONE_OFFSET_SYMBOL = Symbol("globalTimeZone");

export const useDateTime = (
    date: Ref<string | number | Date>,
    format: Ref<CommonTimeTypes>,
    locales: Ref<CommonLocalisationArgument>
) => useDateFormat(date, format, { locales: locales.value });

export const useDateTimeWithOffset = (
    date: Ref<string | number | Date>,
    format: Ref<CommonTimeTypes>,
    locales: Ref<CommonLocalisationArgument>,
    offset: Ref<number> = ref(0)
) => {
    const adjustedDate = computed(() => {
        const originalDate = new Date(date.value);
        const localOffset = -originalDate.getTimezoneOffset() / 60;
        const offsetToAdd = (offset.value - localOffset) * 60 * 60000;

        return new Date(originalDate.getTime() + offsetToAdd);
    });

    return useDateTime(adjustedDate, format, locales);
};
