import { ENVIRONMENT_TYPES, SportCodes } from "@swisstiming/webtec-kit";

interface IAuthorizationList {
    Sports: {
        [property in SportCodes]?: {
            [property in ENVIRONMENT_TYPES]?: IAuthorizationConfiguration;
        };
    };
}

interface IAuthorizationConfiguration {
    IFrameRequired: boolean;
    IFrameWhiteList: string[];
    Redirect: boolean;
}

export const environmentPlaceholder = "ENVIRONMENT-PLACEHOLDER";

// TODO: how we handle profiles..., e.g. for RAC / ATH
export const authorizationList: IAuthorizationList = {
    Sports: {
        [SportCodes.BOBSLEIGH_SKELETON]: {
            UAT: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "sports.swisstiming.com", // uat
                    "www.liveresults.swisstiming.com", // prod
                    ".ibsf.org",
                    "ibsf.stage.infrontams.tv",
                    "omegatiming.com"
                ],
                Redirect: true
            },
            PROD: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "sports.swisstiming.com", // uat
                    "www.liveresults.swisstiming.com", // prod
                    ".ibsf.org",
                    "ibsf.stage.infrontams.tv",
                    "omegatiming.com"
                ],
                Redirect: false
            }
        },
        [SportCodes.LUGE]: {
            UAT: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "sports.swisstiming.com", // uat
                    "www.liveresults.swisstiming.com", // prod
                    "fil-luge.org" // luge site
                ],
                Redirect: true
            },
            PROD: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "sports.swisstiming.com", // uat
                    "www.liveresults.swisstiming.com", // prod
                    "fil-luge.org" // luge site
                ],
                Redirect: false
            }
        },
        [SportCodes.SHORT_TRACK]: {
            UAT: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "app-dev-isu-stk-archive-westeurope.azurewebsites.net",
                    "sports.swisstiming.com", // uat
                    "www.liveresults.swisstiming.com", // prod
                    "app-dev-isu-stk-archive-westeurope.azurewebsites.net",
                    "shorttrack-archive.azureedge.net", // CDN PROD
                    "shorttrack.swisstiming.com" // public PROD
                ],
                Redirect: true
            },
            PROD: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "sports.swisstiming.com", // uat
                    "www.liveresults.swisstiming.com", // prod
                    "shorttrack-archive.swisstiming.com", // prod
                    "app-prod-isu-stk-archive-westeurope.azurewebsites.net", // origin PROD
                    "shorttrack-archive.azureedge.net", // CDN PROD
                    "shorttrack.swisstiming.com" // public PROD
                ],
                Redirect: false
            }
        },
        [SportCodes.RACING]: {
            UAT: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "racing-monitor.liveresults.uat-sports.swisstiming.com", // UAT url
                    "racing-monitor.liveresults.swisstiming.com", // PROD url

                    // SRO
                    "intercontinentalgtchallenge.com",
                    "gt-world-challenge-europe.com",
                    "gt-world-challenge-asia.com",
                    "crowdstrike24hoursofspa.com",
                    "gt2europeanseries.com",
                    "gt4europeanseries.com",
                    "ffsagt.gt4series.com",
                    "ffsatourisme.fr",
                    "msvr.co.uk",

                    // Porsche
                    "content3.eu.porsche.com",
                    "eu.porsche.com",
                    "motorsports.porsche.com",
                    "www.carreracupbenelux.com",
                    "www-carreracupbenelux-com.filesusr.com",
                    "motorsporttest.westeurope.cloudapp.azure.com",
                    "ffsagt.gt4series.com",
                    "ffsatourisme.fr",
                    "gp-elite.nl",
                    "gp-elite.com",

                    // DTM
                    "blancpain-gt-series.com",
                    "gt-world-challenge-europe.com",
                    "dtm.com",

                    // ADAC
                    "blancpain-gt-series.com",
                    "adac-gt-masters.de",
                    "adac-motorsport.de",
                    ".gtsb.io", // ADAC Test pages
                    "d21b4lv63lv0h.cloudfront.net", // ADAC Test page
                    "d3iueunljqrqqb.cloudfront.net", // ADAC Test page
                    "d1ffgttt6ao4cj.cloudfront.net", // ADAC Test page
                    "d2hfejuunv80jw.cloudfront.net", // ADAC Test page
                    "do0kiyfs8ap7h.cloudfront.net", // ADAC Test page
                    "localhost:8000", // Local Test page
                    "dtm.com"
                ],
                Redirect: true
            },
            PROD: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "racing-monitor.liveresults.uat-sports.swisstiming.com", // UAT url
                    "racing-monitor.liveresults.swisstiming.com", // PROD url

                    // SRO
                    "intercontinentalgtchallenge.com",
                    "gt-world-challenge-europe.com",
                    "gt-world-challenge-asia.com",
                    "crowdstrike24hoursofspa.com",
                    "gt2europeanseries.com",
                    "gt4europeanseries.com",
                    "ffsagt.gt4series.com",
                    "ffsatourisme.fr",
                    "msvr.co.uk",

                    // Porsche
                    "content3.eu.porsche.com",
                    "eu.porsche.com",
                    "motorsports.porsche.com",
                    "www.carreracupbenelux.com",
                    "www-carreracupbenelux-com.filesusr.com",
                    "motorsporttest.westeurope.cloudapp.azure.com",
                    "ffsagt.gt4series.com",
                    "ffsatourisme.fr",
                    "gp-elite.nl",
                    "gp-elite.com",

                    // DTM
                    "blancpain-gt-series.com",
                    "gt-world-challenge-europe.com",
                    "dtm.com",

                    // ADAC
                    "blancpain-gt-series.com",
                    "adac-gt-masters.de",
                    "adac-motorsport.de",
                    ".gtsb.io", // ADAC Test pages
                    "d21b4lv63lv0h.cloudfront.net", // ADAC Test page
                    "d3iueunljqrqqb.cloudfront.net", // ADAC Test page
                    "d1ffgttt6ao4cj.cloudfront.net", // ADAC Test page
                    "d2hfejuunv80jw.cloudfront.net", // ADAC Test page
                    "do0kiyfs8ap7h.cloudfront.net", // ADAC Test page
                    "localhost:8000", // Local Test page
                    "dtm.com"
                ],
                Redirect: false
            }
        },
        [SportCodes.ATHLETICS]: {
            UAT: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "framewrapper.liveresults.uat-sports.swisstiming.com", // UAT url
                    "framewrapper.liveresults.swisstiming.com", // PROD url
                    "leichtathletik.de",
                    "www.weltklassezuerich.ch",
                    "istaf.de",
                    "istaf-indoor.de",
                    "duesseldorf.istaf-indoor.de",
                    "fbkgames.nl",
                    "meeting-goetzis.at",
                    "meeting-karlsruhe.de",
                    "omegatiming.com"
                ],
                Redirect: true
            },
            PROD: {
                IFrameRequired: true,
                IFrameWhiteList: [
                    "framewrapper.liveresults.uat-sports.swisstiming.com", // UAT url
                    "framewrapper.liveresults.swisstiming.com", // PROD url
                    "leichtathletik.de",
                    "www.weltklassezuerich.ch",
                    "istaf.de",
                    "istaf-indoor.de",
                    "duesseldorf.istaf-indoor.de",
                    "fbkgames.nl",
                    "meeting-goetzis.at",
                    "meeting-karlsruhe.de",
                    "omegatiming.com"
                ],
                Redirect: false
            }
        }
    }
};
