<template>
    <table class="w-full">
        <thead>
            <tr>
                <th class="sticky left-0 w-12 min-w-12">{{ $getStrByLng().RANK }}</th>
                <th class="w-16 min-w-16"></th>
                <th class="min-w-32 text-left">{{ $getStrByLng().NAME }}</th>
                <template v-if="!isRelay">
                    <th v-for="run in runsOrdered" :key="run.Name">
                        {{ run.Name }}
                    </th>
                </template>
                <th v-if="!isRelay" class="current-run">{{ timingChannel?.NameShort }}</th>
                <th class="w-16 min-w-16">{{ $getStrByLng().TOTAL }}</th>
                <th class="w-16 min-w-16">{{ $getStrByLng().BEHIND }}</th>
            </tr>
        </thead>
        <tbody class="relative">
            <template v-if="sortedResultList?.length > 0">
                <template v-for="competitor in sortedResultList" :key="competitor.Id">
                    <tr
                        :class="[
                            { 'cursor-pointer': isRelay },
                            getTWHighlightCompetitorClass(competitor, currentCompetitorId)
                        ]"
                        @click="toggleTeam(competitor.Id)"
                    >
                        <FalconRankIrm class="!sticky left-0" :competitor="competitor"></FalconRankIrm>
                        <FalconFlag class="w-12" :nat-code="competitor.Nationality" tag="td"></FalconFlag>
                        <td class="text-left leading-none">
                            <WtcName :competitor-name="competitor"></WtcName>
                        </td>
                        <template v-if="!isRelay">
                            <td v-for="run in competitor.Runs" :key="run.Name" class="font-normal_bold leading-none">
                                {{ run.Result }} <br /><span class="font-normal_regular text-xs font-normal">{{
                                    getWrappedValue(run.Rank, "[", "]")
                                }}</span>
                            </td>
                        </template>
                        <td v-if="!isRelay" class="current-run font-normal_bold leading-none">
                            {{ lastIntermediate(competitor)?.Result }}
                            <br /><span class="font-normal_regular text-xs font-normal">{{
                                getWrappedValue(lastIntermediate(competitor)?.Rank, "[", "]")
                            }}</span>
                        </td>
                        <td>{{ competitor.Result }}</td>
                        <td>{{ competitor.Behind }}</td>
                    </tr>
                    <template v-if="isRelay && isTeamShown(competitor.Id)">
                        <div class="sub-table"></div>
                        <tr
                            v-for="teamMember in competitor.Children"
                            :key="teamMember.Id"
                            class="team-member"
                            :class="[getTWHighlightTeamMemberClass(teamMember, currentCompetitorChildId)]"
                        >
                            <td class="text-left" colspan="3">
                                <WtcName class="text-white" :competitor-name="teamMember"></WtcName>
                            </td>
                            <td>{{ teamMember.Result }}</td>
                            <td>{{ teamMember.Behind }}</td>
                        </tr>
                    </template>
                </template>
            </template>
            <template v-else>
                <tr>
                    <WtcPlaceholder
                        class="text-brand font-normal_bold uppercase"
                        colspan="100"
                        tag="td"
                        text="There is no data available at the moment."
                    ></WtcPlaceholder>
                </tr>
            </template>
        </tbody>
    </table>
</template>
<script setup lang="ts">
import {
    FalconFlag,
    FalconRankIrm,
    ISledRun,
    useSledLiveChannel,
    useSledTimingChannel
} from "@swisstiming/falcon-core";
import {
    getTWHighlightCompetitorClass,
    getTWHighlightTeamMemberClass,
    getWrappedValue,
    ICompetitorDetail,
    ICompetitorIntermediate,
    IRsc,
    WtcName,
    WtcPlaceholder
} from "@swisstiming/webtec-kit";
import { orderBy } from "lodash-es";
import { computed, ref } from "vue";

const props = defineProps<{
    rsc?: IRsc;
}>();

const rscValue = computed(() => props.rsc?.Value);

const { sortedResultList, isRelay, timingChannel } = useSledTimingChannel(rscValue);
const { currentCompetitorId, currentCompetitorChildId } = useSledLiveChannel();

const runsOrdered = computed((): ISledRun[] => orderBy(timingChannel?.value.Runs, "Number"));

const lastIntermediate = (competitor: ICompetitorDetail): ICompetitorIntermediate => competitor.Intermediate?.at(-1);

const visibleTeams = ref({});
const toggleTeam = (teamId: string) => {
    const isDisplayed = visibleTeams.value[teamId];
    visibleTeams.value[teamId] = !isDisplayed;
};
const isTeamShown = (teamId: string): boolean => visibleTeams.value[teamId];
</script>
<style lang="scss">
.sub-table {
    @apply w-0 h-0 border-r-8 border-b-8 border-l-8 border-t-transparent border-r-transparent border-b-white border-l-transparent rotate-180 absolute left-4 before:w-0 before:h-0 before:border-r-8 before:border-b-8 before:border-l-8 before:border-t-transparent before:border-r-transparent before:border-b-white before:border-l-transparent before:absolute before:-left-2;

    + tr.team-member > td {
        @apply pt-4;
    }
}

tr.team-member {
    td {
        @apply bg-brand-secondary pt-2 leading-none text-white;
    }
}

tr.current-team-member {
    td {
        @apply text-content-highlight;

        .wtc-name {
            @apply text-content-highlight;
        }
    }
}

tr:nth-child(odd) {
    + .sub-table {
        @apply before:border-b-surface-alternate;
    }
}
tr.current-competitor {
    > td {
        @apply bg-surface-highlightLight;

        &:first-child {
            @apply before:h-full before:border-l-4 before:border-surface-highlight before:content-['*'] before:absolute before:left-0 before:top-0 before:text-transparent relative;
        }
    }

    + .sub-table {
        @apply before:border-b-surface-highlight/10;
    }

    &:nth-child(odd) {
        + .sub-table {
            @apply border-b-surface-secondary before:border-b-surface-highlight/10;
        }
    }

    .current-run {
        @apply after:border-b-2 after:border-surface-highlight after:content-['*'] after:absolute after:bottom-0 after:left-0 after:text-transparent after:w-full relative;
    }
}
</style>
