import {
    getSledMeasureBoxChannelName,
    ISledLiveChannel,
    ISledMeasureBoxChannel,
    ISledTimingChannel,
    useFalconStore,
    useSledLiveChannelName
} from "@swisstiming/falcon-core";
import {
    CommonCompTypes,
    getTimingChannel,
    ICompetitorDetail,
    useChannel,
    useMapIdToCompetitor
} from "@swisstiming/webtec-kit";
import { computed, Ref } from "vue";

export const useSledLiveChannel = () => {
    const falconStore = useFalconStore();
    const currentSledChannelName = computed(() => useSledLiveChannelName(falconStore.config.tournamentId));
    const { content: currentChannel, status } = useChannel<ISledLiveChannel>(currentSledChannelName);

    const currentCompetitor = computed(() => currentChannel.value.Current);
    const currentCompetitorId = computed(() => currentCompetitor.value?.Id);
    const currentChild = computed(
        () => currentChannel.value.Current?.Children?.[currentChannel.value.Current?.CurrentChildId]
    );
    const currentCompetitorChildId = computed(() => currentChild.value?.Id);

    const isRelay = computed(
        () => currentChannel.value.Stats?.Type?.toLowerCase() === CommonCompTypes.RELAY.toLowerCase()
    );

    // In case of a Team Relay (Stats.Type = "Relay) in Current.Children[X].Stats.FalseStartStatus (at the athlete who made the potential false start)
    // In all other event types in Current.Stats.FalseStartStatus
    const currentCompetitorFalseStartStatus = computed(() => {
        if (isRelay.value) {
            return currentChild?.value?.Stats?.FalseStartStatus;
        } else {
            return currentCompetitor?.value.Stats?.FalseStartStatus;
        }
    });

    const currentCompetitorPenaltyTime = computed(
        () => (currentChild.value ? currentChild : currentCompetitor)?.value.Stats?.PenaltyTime
    );

    return {
        currentCompetitorChildId,
        currentCompetitor,
        currentCompetitorId,
        currentChild,
        currentChannel,
        isRelay,
        status,
        currentCompetitorPenaltyTime,
        currentCompetitorFalseStartStatus
    };
};

export const useSledTimingChannel = (rsc?: Ref<string>) => {
    const falconStore = useFalconStore();
    const channelName = computed(() => getTimingChannel(falconStore.config.tournamentId, rsc.value));
    const { content: timingChannel } = useChannel<ISledTimingChannel>(channelName);

    const isRelay = computed(
        () => timingChannel.value.Stats?.Type?.toLowerCase() === CommonCompTypes.RELAY.toLowerCase()
    );

    const sortedStartList = computed((): ICompetitorDetail[] =>
        useMapIdToCompetitor(timingChannel.value.CompetitorDetails, timingChannel.value.Startlist)
    );

    const sortedResultList = computed((): ICompetitorDetail[] =>
        useMapIdToCompetitor(timingChannel.value.CompetitorDetails, timingChannel.value.Resultlist)
    );

    const isActiveCompetitor = (competitor: ICompetitorDetail, currentId: string) => currentId === competitor?.Id;

    return {
        isActiveCompetitor,
        isRelay,
        sortedResultList,
        sortedStartList,
        timingChannel
    };
};

export const useSledMeasureBoxChannel = () => {
    const falconStore = useFalconStore();
    const channelName = computed(() => getSledMeasureBoxChannelName(falconStore.config.tournamentId));
    const { content: measureBoxChannel } = useChannel<ISledMeasureBoxChannel>(channelName);

    return {
        measureBoxChannel
    };
};
