export const toOrdinal = (value: string | number): string => {
    // TODO: consider translation
    const valueNumber = parseInt(value?.toString(), 10);
    if (isNaN(valueNumber)) {
        return value?.toString();
    }

    let result = valueNumber.toString();
    if (valueNumber == 11 || valueNumber == 12 || valueNumber == 13) {
        result += "th";
    } else {
        switch (valueNumber % 10) {
            case 1:
                result += "st";
                break;
            case 2:
                result += "nd";
                break;
            case 3:
                result += "rd";
                break;
            default:
                result += "th";
                break;
        }
    }
    return result;
};
