export enum SportTypes {
    Combat = "Combat",
    Timing = "Timing",
    Scoring = "Scoring",
    Racquet = "Racquet",
    Team = "Team",
    None = "None"
}

export enum SportCodes {
    ATHLETICS = "ath",
    BOBSLEIGH_SKELETON = "bsn",
    BOBSLEIGH = "bob",
    CROSS_COUNTRY = "ccs",
    LUGE = "lug",
    NORDIC_COMBINED = "ncb",
    RACING = "rac",
    SHORT_TRACK = "stk",
    SKI_JUMPING = "sjp"
}

export const SPORTS = {
    AIKIDO: { name: "Aikido", sportCode: "aik", sportType: SportTypes.Combat },
    ALPINE: { name: "Alpine", sportCode: "alp", sportType: SportTypes.Timing },
    ARMWRESTLING: { name: "Armwrestling", sportCode: "arm", sportType: SportTypes.Combat },
    ARTISTIC_SWIMMING: { name: "Artistic Swimming", sportCode: "swa", sportType: SportTypes.Scoring },
    ATHLETICS: { name: "Athletics", sportCode: SportCodes.ATHLETICS, sportType: SportTypes.Timing },
    BADMINTON: { name: "Badminton", sportCode: "bdm", sportType: SportTypes.Racquet },
    BASKETBALL: { name: "Basketball", sportCode: "bkb", sportType: SportTypes.Team },
    BASKETBALL_3X3: { name: "Basketball 3x3", sportCode: "bk3", sportType: SportTypes.Team },
    BEACH_VOLLEYBALL: { name: "Beach Volleyball", sportCode: "vbv", sportType: SportTypes.Team },
    BOBSLEIGH: { name: "Bobsleigh", sportCode: SportCodes.BOBSLEIGH, sportType: SportTypes.Timing },
    BOXING: { name: "Boxing", sportCode: "box", sportType: SportTypes.Combat },
    CANOE_FLATWATER: { name: "Canoe Flatwater", sportCode: "csp", sportType: SportTypes.Timing },
    CRICKET: { name: "Cricket T20", sportCode: "ckt", sportType: SportTypes.Team },
    CROSS_COUNTRY: { name: "Cross Country", sportCode: SportCodes.CROSS_COUNTRY, sportType: SportTypes.Timing },
    CYCLING_MOUNTAIN_BIKE: { name: "Cycling Mountain Bike", sportCode: "mtb", sportType: SportTypes.Timing },
    CYCLING_ROAD: { name: "Cycling Road", sportCode: "crd", sportType: SportTypes.Timing },
    CYCLING_TRACK: { name: "Cycling Track", sportCode: "ctr", sportType: SportTypes.Timing },
    DIVING: { name: "Diving", sportCode: "div", sportType: SportTypes.Scoring },
    FENCING: { name: "Fencing", sportCode: "fen", sportType: SportTypes.Combat },
    FIGURE_SKATING: { name: "Figure Skating", sportCode: "fsk", sportType: SportTypes.Scoring },
    GOLF: { name: "Golf", sportCode: "glf", sportType: SportTypes.Scoring },
    GYMNASTICS_ARTISTIC: { name: "Gymnastics Artistic", sportCode: "gar", sportType: SportTypes.Timing },
    GYMNASTICS_RHYTHMIC: { name: "Gymnastics Rhythmic", sportCode: "gry", sportType: SportTypes.Timing },
    HANDBALL: { name: "Handball", sportCode: "hbl", sportType: SportTypes.Team },
    HOCKEY: { name: "Hockey", sportCode: "hoc", sportType: SportTypes.Team },
    JUDO: { name: "Judo", sportCode: "jud", sportType: SportTypes.Combat },
    JUJITSU: { name: "Ju-Jitsu", sportCode: "jji", sportType: SportTypes.Combat },
    KARATE: { name: "Karate", sportCode: "kte", sportType: SportTypes.Combat },
    KENDO: { name: "Kendo", sportCode: "kdo", sportType: SportTypes.Combat },
    KICKBOXING: { name: "Kickboxing", sportCode: "kik", sportType: SportTypes.Combat },
    LAWN_BOWLS: { name: "Lawn Bowls", sportCode: "lbo", sportType: SportTypes.Team },
    LIVE_SAVING: { name: "Live Saving", sportCode: "lfc", sportType: SportTypes.Timing },
    LUGE: { name: "Luge", sportCode: SportCodes.LUGE, sportType: SportTypes.Timing },
    MARATHON_SWIMMING: { name: "Marathon Swimming", sportCode: "ows", sportType: SportTypes.Timing },
    MUAYTHAI: { name: "Muaythai", sportCode: "mti", sportType: SportTypes.Combat },
    NETBALL: { name: "Netball", sportCode: "nbl", sportType: SportTypes.Team },
    NORDIC_COMBINED: { name: "Nordic Combined", sportCode: SportCodes.NORDIC_COMBINED, sportType: SportTypes.Timing },
    POWERLIFTING: { name: "Powerlifting", sportCode: "pwl", sportType: SportTypes.Scoring },
    RACING: { name: "Racing", sportCode: SportCodes.RACING, sportType: SportTypes.Timing },
    ROLLER_SKATING_SPEED: { name: "Roller Skating Speed", sportCode: "rol", sportType: SportTypes.Timing },
    ROWING: { name: "Rowing", sportCode: "row", sportType: SportTypes.Timing },
    RUGBY: { name: "Rugby Sevens", sportCode: "rug", sportType: SportTypes.Team },
    SAMBO: { name: "Sambo", sportCode: "sbo", sportType: SportTypes.Combat },
    SAVATE: { name: "Savate", sportCode: "sav", sportType: SportTypes.Combat },
    SHOOTING: { name: "Shooting", sportCode: "sho", sportType: SportTypes.Scoring },
    SHORT_TRACK: { name: "Short Track", sportCode: SportCodes.SHORT_TRACK, sportType: SportTypes.Timing },
    SKELETON: { name: "Skeleton", sportCode: "skn", sportType: SportTypes.Timing },
    SKI_JUMPING: { name: "Ski Jumping", sportCode: SportCodes.SKI_JUMPING, sportType: SportTypes.Scoring },
    SQUASH: { name: "Squash", sportCode: "squ", sportType: SportTypes.Racquet },
    SUMO: { name: "Sumo", sportCode: "sum", sportType: SportTypes.Combat },
    SWIMMING: { name: "Swimming", sportCode: "swm", sportType: SportTypes.Timing },
    TABLE_TENNIS: { name: "Table Tennis", sportCode: "tte", sportType: SportTypes.Racquet },
    TAEKWONDO: { name: "Taekwondo", sportCode: "tkw", sportType: SportTypes.Combat },
    TRIATHLON: { name: "Triathlon", sportCode: "tri", sportType: SportTypes.Timing },
    WATERPOLO: { name: "Waterpolo", sportCode: "wpo", sportType: SportTypes.Team },
    WEIGHTLIFTING: { name: "Weightlifting", sportCode: "wlf", sportType: SportTypes.Scoring },
    WRESTLING: { name: "Wrestling", sportCode: "wre", sportType: SportTypes.Combat },
    WUSHU: { name: "Wushu", sportCode: "wsu", sportType: SportTypes.Combat }
};
