export enum ENVIRONMENT_TYPES {
    DEV = "DEV",
    UAT = "UAT",
    DEMO = "DEMO",
    PROD = "PROD"
}

export enum NAMESPACE_TYPES {
    DEV = "DEV",
    TEST = "TEST",
    DEMO = "DEMO",
    PROD = "PROD"
}

export enum MODES {
    DEV_SHORT = "dev",
    DEV = "development",
    DEMO = "demo",
    UAT = "uat",
    PROD_SHORT = "prod",
    PROD = "production"
}
export enum COMMON_QUERY_NAMES {
    SEASON = "season",
    EVENT = "event",
    NAMESPACE = "namespace",
    HOST = "host",
    MODE = "mode",
    LANGUAGE = "lng",
    PROFILE = "profile",
    USE_HTTPS = "useHttps"
}
