import { isNil } from "lodash-es";
import { Directive } from "vue";

/**
 * Add a common highlight directive, which applies a class to trigger a highlight on value changes.
 */
export const wtcHighlightDirective: Directive = {
    updated: (el, { value, oldValue }) => {
        if (!isNil(value) && value != oldValue) {
            el.classList.remove("x--highlight--value-changed");
            setTimeout(() => {
                el.classList.add("x--highlight--value-changed");
            }, 1);
        }
    }
};
