<template>
    <WtcPlaceholder
        class="max-tablet:text-xl max-tablet:pt-12 font-normal_light absolute flex size-full justify-center bg-gradient-to-b from-gray-400 to-gray-300 pt-52 text-center text-3xl uppercase text-gray-800"
        :text="displayedMessage"
    ></WtcPlaceholder>
</template>
<script setup lang="ts">
import { getStrByLng, WtcPlaceholder } from "@swisstiming/webtec-kit";
import { computed, PropType } from "vue";

const props = defineProps({
    msg: {
        type: String as PropType<"no-event" | "no-data" | "no-access">
    }
});

const displayedMessage = computed(() => {
    switch (props.msg) {
        case "no-event":
            return getStrByLng().MESSAGE_NO_EVENT_AVAILABLE;
        case "no-data":
            return getStrByLng().MESSAGE_NO_DATA_AVAILABLE;
        case "no-access":
            return getStrByLng().NO_ACCESS_MESSAGE;
    }
});
</script>

<style scoped lang="scss"></style>
