<template>
    {{ dateString }}
</template>

<script setup lang="ts">
import { computed, inject, Prop, Ref, toRef, toRefs } from "vue";
import { PropType } from "vue";

import {
    CommonLocalisationArgument,
    CommonTimeTypes,
    LOCALISATION_SYMBOL,
    TIME_ZONE_OFFSET_SYMBOL,
    useDateTime,
    useDateTimeWithOffset
} from "../../utils/time.utils";

const props = defineProps({
    date: {
        type: [String, Number, Date]
    },
    format: {
        type: String as PropType<CommonTimeTypes>,
        default(): CommonTimeTypes {
            return "DD.MM.YYYY, HH:mm";
        }
    },
    locales: String as Prop<CommonLocalisationArgument>,
    timeZone: Number
});
const { date, format } = toRefs(props);

const timeZoneInject: Ref<number> = inject(TIME_ZONE_OFFSET_SYMBOL, undefined);
const localesInject: Ref<CommonLocalisationArgument> = inject(LOCALISATION_SYMBOL, undefined);

const chosenTimeZone = computed(() => props.timeZone ?? timeZoneInject?.value);
const chosenLocalisation = computed(() => props.locales ?? localesInject?.value);

const dateString = computed(() => {
    if (chosenTimeZone.value !== undefined) {
        return useDateTimeWithOffset(date, format, chosenLocalisation, chosenTimeZone).value;
    } else {
        return useDateTime(toRef(props, "date"), toRef(props, "format"), localesInject).value;
    }
});
</script>

<style lang="scss"></style>
