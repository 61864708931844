import { PostMessagKeyTypes } from "@swisstiming/falcon-core";

interface IMessageEvent {
    data: IMessageEventData;
    origin: string;
    lastEventId: string;
    source: object;
}

interface IMessageEventData {
    key: PostMessagKeyTypes;
    value: string;
}

export const setupIframeCrossdomainCommunication = () => {
    let crossDomain: string;
    let resizeObserver: ResizeObserver;

    const sendHeightMessage = () => {
        if (window.parent.postMessage && crossDomain) {
            const bodyHeight = Math.max(document.body.clientHeight, document.body.offsetHeight);

            // defined object as payload send to embedding domain
            const data = {
                key: PostMessagKeyTypes.HEIGHT,
                value: bodyHeight
            };
            // sends defined payload as message to embedding domain
            window.parent.postMessage(data, crossDomain);
        }
    };

    window.addEventListener(
        "message",
        (event: IMessageEvent) => {
            if (typeof event.data === "object" && event.data.key) {
                switch (event.data.key) {
                    case PostMessagKeyTypes.HOST:
                        {
                            crossDomain = event.data.value;
                            if (!resizeObserver) {
                                resizeObserver = new ResizeObserver(sendHeightMessage);
                                resizeObserver.observe(document.body);
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        },
        false
    );
};
