<template>
    <div>
        <div class="font-normal_bold p-2 text-sm">{{ $getStrByLng().LIVE_TIMING }}</div>
        <div
            v-for="round in roundsOrdered"
            :key="round.UnitName"
            class="mb-2 border-b border-black/10 pb-2 text-sm last:mb-0 last:border-b-0"
        >
            <div class="mb-1 flex px-2">
                <div class="font-normal_bold flex-1">{{ toOrdinal(round.Number) }} {{ $getStrByLng().RUN }}</div>

                <div v-if="showAdvantage" class="ml-3 flex text-xs">
                    <span>{{ $getStrByLng().LABEL_ADVANTAGE }}:</span>
                    <span class="text-content-positive font-normal_bold ml-1">{{
                        currentChannel.Stats.Advantage
                    }}</span>
                </div>

                <div v-if="round.Record" class="ml-3 flex text-xs">
                    <div>{{ $getStrByLng().RECORD }}:</div>
                    <div class="font-normal_bold ml-1">{{ round.Record }}</div>
                </div>
            </div>
            <ul class="flex flex-wrap">
                <li
                    v-for="intermediate in round.Intermediate"
                    :key="intermediate.Name"
                    class="bg-surface-alternate min-w-16 text-center"
                >
                    <div
                        class="bg-brand-secondary max-tablet:px-1 font-normal_light h-5 px-4 py-1 text-xs uppercase leading-none text-white"
                    >
                        {{ intermediate.Name }}
                    </div>
                    <div class="font-normal_bold min-h-12 p-2 text-sm leading-none">
                        {{ intermediate.Result }}<br /><span class="font-regular text-xs font-normal">{{
                            getWrappedValue(intermediate.Rank, "[", "]")
                        }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ISledCurrentCompetitor, useSledLiveChannel } from "@swisstiming/falcon-core";
import { getWrappedValue, toOrdinal } from "@swisstiming/webtec-kit";
import { BsnCompetitorStatus } from "bsn-results/src/enums/bsn.enums";
import { orderBy } from "lodash-es";
import { computed } from "vue";

const props = defineProps<{
    competitor: ISledCurrentCompetitor;
}>();

const { currentChannel } = useSledLiveChannel();

const currentCompetitor = computed(() =>
    props.competitor.IsTeam && props.competitor.CurrentChildId
        ? props.competitor.Children?.[props.competitor.CurrentChildId]
        : props.competitor
);
const roundsOrdered = computed(() => orderBy(currentCompetitor.value.RoundRes, "ListIndex"));

const showAdvantage = computed(() => {
    const advantage = parseFloat(currentChannel.value?.Stats?.Advantage);
    const status = currentChannel.value?.Stats?.Status;

    return !isNaN(advantage) && advantage < 0 && status !== BsnCompetitorStatus.FINISHED;
});
</script>
