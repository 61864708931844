import {
    getSledMeasureBoxChannelName,
    ISledLiveChannel,
    ISledMeasureBoxChannel,
    ISledTimingChannel,
    useFalconStore,
    useSledLiveChannelName
} from "@swisstiming/falcon-core";
import {
    CommonCompTypes,
    getTimingChannel,
    ICompetitorDetail,
    useChannel,
    useMapIdToCompetitor
} from "@swisstiming/webtec-kit";
import { computed, Ref } from "vue";

export const useSledLiveChannel = () => {
    const falconStore = useFalconStore();
    const currentSledChannelName = computed(() => useSledLiveChannelName(falconStore.config.tournamentId));
    const { content: currentChannel, status } = useChannel<ISledLiveChannel>(currentSledChannelName);

    const currentCompetitorId = computed(() => currentChannel.value.Current?.Id);
    const currentCompetitorChildId = computed(
        () => currentChannel.value.Current?.Children[currentChannel.value.Current?.CurrentChildId]?.Id
    );

    const isRelay = computed(
        () => currentChannel.value.Stats?.Type?.toLowerCase() === CommonCompTypes.RELAY.toLowerCase()
    );

    return {
        currentCompetitorChildId,
        currentCompetitorId,
        currentChannel,
        isRelay,
        status
    };
};

export const useSledTimingChannel = (rsc?: Ref<string>) => {
    const falconStore = useFalconStore();
    const channelName = computed(() => getTimingChannel(falconStore.config.tournamentId, rsc.value));
    const { content: timingChannel } = useChannel<ISledTimingChannel>(channelName);

    const isRelay = computed(
        () => timingChannel.value.Stats?.Type?.toLowerCase() === CommonCompTypes.RELAY.toLowerCase()
    );

    const sortedStartList = computed((): ICompetitorDetail[] =>
        useMapIdToCompetitor(timingChannel.value.CompetitorDetails, timingChannel.value.Startlist)
    );

    const sortedResultList = computed((): ICompetitorDetail[] =>
        useMapIdToCompetitor(timingChannel.value.CompetitorDetails, timingChannel.value.Resultlist)
    );

    const isActiveCompetitor = (competitor: ICompetitorDetail, currentId: string) => currentId === competitor?.Id;

    return {
        isActiveCompetitor,
        isRelay,
        sortedResultList,
        sortedStartList,
        timingChannel
    };
};

export const useSledMeasureBoxChannel = () => {
    const falconStore = useFalconStore();
    const channelName = computed(() => getSledMeasureBoxChannelName(falconStore.config.tournamentId));
    const { content: measureBoxChannel } = useChannel<ISledMeasureBoxChannel>(channelName);

    return {
        measureBoxChannel
    };
};
