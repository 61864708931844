import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { getStrings, handleError, wtcHighlightDirective, wtcUIPlugin } from "@swisstiming/webtec-kit";
import { createPinia } from "pinia";
import { Plugin } from "vue";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import { IFalconStoreOptions, useFalconStore } from "../stores/falcon.store";

interface IFalconPluginOptions {
    title: string;
    routes?: RouteRecordRaw[];
    storeOptions: IFalconStoreOptions;
}

export const falconPlugin: Plugin<IFalconPluginOptions> = {
    install(app, options) {
        // App defaults
        console.info(`Application ${__APP_NAME__} version ${__APP_VERSION__}`);
        document.title = options.title;

        // Install router if necessary
        if (options.routes) {
            const router = createRouter({
                history: createWebHashHistory(),
                routes: options.routes
            });
            app.use(router);
        }

        // Additional Plugins and directives ready for every federation result page
        app.use(wtcUIPlugin);
        app.use(createPinia());

        // Directives
        app.directive("highlight", wtcHighlightDirective);

        // Global Components
        // TODO: why two times with different name?
        app.component("font-awesome-icon", FontAwesomeIcon);
        app.component("FontAwesomeIcon", FontAwesomeIcon);

        // Init Store and connect to Pushserver, but do not subscribe any channel, as these might all be different.
        const { initFalconStore } = useFalconStore();
        app.config.globalProperties.$getStrByLng = () => getStrings();
        initFalconStore(options.storeOptions).catch(handleError);

        return app;
    }
};
