import { merge } from "lodash-es";
import { defineStore } from "pinia";
import { reactive } from "vue";

export const DEFAULT_LANGUAGE_CODE = "en";

export const useTranslationStore = defineStore("TranslationStore", () => {
    const stringsOfLng = reactive<{
        languageValues: { [strKey: string]: string };
    }>({ languageValues: {} });

    const setStrings = (lngStrings = {}) => {
        stringsOfLng.languageValues = merge({}, stringsOfLng.languageValues, lngStrings);
    };
    return { stringsOfLng, setStrings };
});

export const getStrings = () => useTranslationStore().stringsOfLng.languageValues;
export const getStrByLng = getStrings;
