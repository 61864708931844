<template>
    <div>
        <div class="font-normal_bold p-2 text-sm">{{ $getStrByLng().LIVE_TIMING }}</div>
        <div class="mb-2 border-b border-black/10 pb-2 text-sm last:mb-0 last:border-b-0">
            <div class="mb-1 flex items-center px-2">
                <div class="font-normal_bold flex-1">{{ currentChannel.UnitName }}</div>

                <WtcTransition mode="out-in" name="slide-up">
                    <div
                        v-if="mappedStatusLabel"
                        :key="currentChannel.Stats?.Status"
                        class="rounded px-2 py-1 text-xs"
                        :class="statusStyle"
                    >
                        {{ mappedStatusLabel }}
                    </div>
                </WtcTransition>

                <div v-if="showAdvantage" class="ml-3 flex text-xs">
                    <span>{{ $getStrByLng().LABEL_ADVANTAGE }}:</span>
                    <span class="text-content-positive font-normal_bold ml-1">{{
                        currentChannel.Stats.Advantage
                    }}</span>
                </div>

                <div v-if="currentCompetitor.Record" class="ml-3 flex text-xs">
                    <div>{{ $getStrByLng().RECORD }}:</div>
                    <div class="font-normal_bold ml-1">{{ currentCompetitor.Record }}</div>
                </div>
            </div>
            <ul class="flex flex-wrap">
                <li
                    v-for="intermediate in currentCompetitor.Intermediate"
                    :key="intermediate.Name"
                    class="bg-surface-alternate min-w-16 text-center"
                >
                    <div
                        class="bg-brand-secondary max-tablet:px-1 font-normal_light h-5 px-4 py-1 text-xs uppercase leading-none text-white"
                    >
                        {{ intermediate.Name }}
                    </div>
                    <div class="font-normal_bold min-h-12 p-2 text-sm leading-none">
                        {{ intermediate.Result }}
                        <br />
                        <FalconBehind
                            :behind="intermediate.Behind"
                            class="font-regular text-xs font-normal"
                            tag="span"
                        ></FalconBehind>
                        <br />
                        <span class="font-regular text-xs font-normal">{{
                            getWrappedValue(intermediate.Rank, "[", "]")
                        }}</span>
                    </div>
                </li>
            </ul>
        </div>

        <div v-if="isRelay">
            <table>
                <tbody>
                    <tr v-for="teamMember in competitor.Children" :key="teamMember.Id" class="team-member">
                        <td class="text-left" colspan="3">
                            <WtcName :competitor-name="teamMember"></WtcName>
                        </td>
                        <td>{{ teamMember.Result }}</td>
                        <td>{{ teamMember.Behind }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script setup lang="ts">
import { FalconBehind, ISledCurrentCompetitor, useSledLiveChannel } from "@swisstiming/falcon-core";
import { mapStatusToLabel, SledCompetitorStatus } from "@swisstiming/sled-core";
import { getTWHighlightTeamMemberClass, getWrappedValue, WtcName, WtcTransition } from "@swisstiming/webtec-kit";
import { computed } from "vue";

const props = defineProps<{
    competitor: ISledCurrentCompetitor;
}>();

const { currentChannel, isRelay } = useSledLiveChannel();
const currentCompetitor = computed(() =>
    props.competitor.IsTeam && props.competitor.CurrentChildId
        ? props.competitor.Children?.[props.competitor.CurrentChildId]
        : props.competitor
);

const showAdvantage = computed(() => {
    const advantage = parseFloat(currentChannel.value?.Stats?.Advantage);
    const status = currentChannel.value?.Stats?.Status;

    return !isNaN(advantage) && advantage < 0 && status !== SledCompetitorStatus.FINISHED;
});

const mappedStatusLabel = computed(() => {
    return mapStatusToLabel(currentChannel.value?.Stats?.Status);
});

const statusStyle = computed(() => {
    switch (currentChannel.value?.Stats?.Status) {
        case SledCompetitorStatus.AT_START:
            return "bg-surface-comingup text-content-comingup";
        case SledCompetitorStatus.ON_TRACK:
            return "bg-surface-running text-content-running";
        case SledCompetitorStatus.FINISHED:
            return "bg-surface-finished text-content-finished";
        default:
            return "bg-black text-white";
    }
});
</script>
