<template>
    <div class="flex flex-row items-center">
        <FalconFlag :flag-code="competitor.Nationality"></FalconFlag>
        <span class="ml-2 text-sm">{{ competitor.Bib }}</span>
        <WtcName class="font-normal_bold ml-2 text-lg" :competitor-name="competitor"></WtcName>
        <WtcName v-if="currentChild" class="ml-2 uppercase" :competitor-name="currentChild"></WtcName>
    </div>
</template>
<script setup lang="ts">
import { FalconFlag, ISledCurrentCompetitor } from "@swisstiming/falcon-core";
import { WtcName } from "@swisstiming/webtec-kit";
import { computed } from "vue";

const props = defineProps<{
    competitor: ISledCurrentCompetitor;
}>();

const currentChild = computed(() =>
    props.competitor.IsTeam && props.competitor.CurrentChildId !== undefined
        ? props.competitor.Children?.[props.competitor.CurrentChildId]
        : undefined
);
</script>
