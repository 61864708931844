import { ENVIRONMENT_TYPES, SportCodes } from "@swisstiming/webtec-kit";

import { authorizationList } from "../../../../authorization";

export const checkAuthorization = (sportCode: SportCodes, namespace: ENVIRONMENT_TYPES): boolean => {
    const hasSurroundingIframe = window.parent.frames.length > 0;

    //return false;

    // allow DEV
    if (namespace === ENVIRONMENT_TYPES.DEV || location.href.includes("localhost:51")) {
        return true;
    }

    const sport = authorizationList.Sports?.[sportCode];
    const environmentConfiguration = sport?.[namespace];

    // not configured - not allowed
    if (!environmentConfiguration) {
        return false;
    }

    // no Iframe is required - allowed
    if (environmentConfiguration.IFrameRequired === false) {
        return true;
    }

    // has Iframe ?
    if (hasSurroundingIframe === false) {
        return false;
    }

    // check whitelist
    const whiteListedItem = environmentConfiguration.IFrameWhiteList.find((i) => document.referrer?.includes(i));

    if (whiteListedItem) {
        // on the whitelist
        return true;
    } else if (environmentConfiguration.Redirect) {
        // not on whitelist then redirect
        const redirectUrl =
            namespace === ENVIRONMENT_TYPES.PROD
                ? "https://framewrapper.liveresults.swisstiming.com"
                : "https://framewrapper.liveresults.uat-sports.swisstiming.com";
        document.location.href = `${location.protocol}//${redirectUrl}?target=${location.host}${location.pathname}${location.search}`;
    }

    return false;
};
