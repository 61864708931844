<template>
    <FalconFrame>
        <WtcChannel :channel-name="curChannelName">
            <template #slot__result>
                <WtcTransition appear :delay-ms="0" :duration-ms="200" name="slide-down-only-in">
                    <LugHeader />
                </WtcTransition>
                <WtcTransition appear :delay-ms="200" :duration-ms="200" name="slide-down-only-in">
                    <LugResults />
                </WtcTransition>
            </template>
            <template #slot__not-initialized>
                <FalconPlaceHolder msg="no-event"></FalconPlaceHolder>
            </template>
        </WtcChannel>
    </FalconFrame>
</template>

<script setup lang="ts">
import { FalconFrame, FalconPlaceHolder, useFalconStore, useSledLiveChannelName } from "@swisstiming/falcon-core";
import { WtcChannel, WtcTransition } from "@swisstiming/webtec-kit";
import { computed } from "vue";

import LugHeader from "./components/header/LugHeader.vue";
import LugResults from "./components/results/LugResults.vue";
const curChannelName = computed(() => useSledLiveChannelName(useFalconStore().config.tournamentId));
</script>
<style>
body {
    @apply bg-surface;
}
</style>
