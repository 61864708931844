import { ENVIRONMENT_TYPES, MODES, NAMESPACE_TYPES } from "../enums/environment.enums";
export const getEnvironmentFromMode = (mode: MODES) => {
    switch (mode) {
        case MODES.DEV:
        case MODES.DEV_SHORT:
            return ENVIRONMENT_TYPES.DEV;
        case MODES.DEMO:
            return ENVIRONMENT_TYPES.DEMO;
        case MODES.UAT:
            return ENVIRONMENT_TYPES.UAT;
        case MODES.PROD:
        case MODES.PROD_SHORT:
            return ENVIRONMENT_TYPES.PROD;
        default:
            return ENVIRONMENT_TYPES.PROD;
    }
};

export const getNamespaceSuffixFromMode = (mode: MODES) => {
    switch (mode) {
        case MODES.DEV:
        case MODES.DEV_SHORT:
            return NAMESPACE_TYPES.DEV;
        case MODES.DEMO:
            return NAMESPACE_TYPES.DEMO;
        case MODES.UAT:
            return NAMESPACE_TYPES.TEST;
        case MODES.PROD:
        case MODES.PROD_SHORT:
            return NAMESPACE_TYPES.PROD;
        default:
            return NAMESPACE_TYPES.PROD;
    }
};
