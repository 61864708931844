import { useFalconStore } from "@swisstiming/falcon-core";
import { Ref } from "vue";

export const useIrmName = (irm: Ref<string>): string => {
    if (!irm?.value) {
        return;
    }
    const falconStore = useFalconStore();
    return falconStore.data.standingData?.Statusses?.[irm?.value]?.Code;
};
