import { isEmpty } from "lodash-es";

import { RecordAreaType } from "../interfaces/channel/commons";

export const CHANNEL_PLACEHOLDER = {
    TOURNAMENT_ID: "#TOURNAMENT-ID#",
    SEASON_ID: "#SEASON-ID#",
    RSC_EVENT: "#RSC-EVENT#",
    RSC_PHASE: "#RSC-PHASE#",
    RSC_UNIT: "#RSC-UNIT#",
    LOCATION_RSC_EVENT: "#RSC-EVENT#",
    LOCATION_RSC_PHASE: "#RSC-PHASE#",
    LOCATION_RSC_UNIT: "#RSC-UNIT#",
    LOCATION_ID: "#LOC#",
    SUB_LOCATION_ID: "#SUB-LOC#",
    RESULT_TYPE: "#RESULT-TYPE#",
    COMPETITOR_ID: "#COMPETITOR-ID#"
};

export const RESULT_TYPES_NAMES = {
    EVENT_TIMING: "EVENT_TIMING",
    TIMING: "TIMING",
    JUDGEMENT: "JUDGEMENT"
};

export const CHANNELS_NAMES = {
    CURRENT_EVENT: "CURRENTEVENT_JSON",
    CIS_CONFIG: "CIS_CONFIG_JSON",
    CIS_CONFIG_STATUS: "CIS_CONFIG_STATUS_JSON",
    EVENT_INFO: `${CHANNEL_PLACEHOLDER.TOURNAMENT_ID}_CURRENTEVENT_JSON`,
    SEASON: `SEASON_${CHANNEL_PLACEHOLDER.SEASON_ID}_JSON`
};

export const createFullChannelName = (namespace: string, channel: string): string => `${namespace}|${channel}`;

export const createNameSpaceChannel = (namespace: string, channelName: string): string => {
    if (!isEmpty(channelName) && channelName.includes("|")) {
        return channelName;
    } else {
        return createFullChannelName(namespace, channelName);
    }
};

export const getCurrentEventChannel = (eventId) => `${eventId}_CURRENT_JSON`;

export const getCurrentChannel = (eventId: string) => {
    if (eventId) {
        return `${eventId}_CURRENT_JSON`;
    }
};

export const getEventSummaryChannel = (rsc, eventId, type = RESULT_TYPES_NAMES.TIMING) =>
    `${eventId}_EVENTSUMMARY-${type}_${rsc}_JSON`;

export const getPhaseSummaryChannel = (rsc, eventId, type = RESULT_TYPES_NAMES.TIMING) =>
    `${eventId}_PHASESUMMARY-${type}_${rsc}_JSON`;

export const getTimingChannel = (tournamentId: string, rsc: string) => {
    if (tournamentId && rsc) {
        return `${tournamentId}_TIMING_${rsc}_JSON`;
    }
};

export const getFlashQuotesChannel = (tournamentId: string) => {
    if (tournamentId) {
        return `${tournamentId}_FLASHINTERVIEW_JSON`?.toUpperCase();
    }
};
export const getCompRecordsChannel = (tournamentId: string, recCode: string) => {
    if (tournamentId && recCode) {
        return `${tournamentId}_COMPRECORDS_${recCode}_JSON`;
    }
};
export const getLiveChannel = (tournamentId: string) => `${tournamentId}_LIVE_JSON`;

export const getMedalTableChannel = (tournamentId) => `${tournamentId}_REPORT_STANDING_MEDALTABLE_JSON`;
export const getMedallistsChannel = (tournamentId) => `${tournamentId}_MEDALLISTBYEVENT_JSON`;

export const getScheduleChannel = (tournamentId, nameSuffix?) =>
    tournamentId ? `${tournamentId}_SCHEDULE_${nameSuffix ? `${nameSuffix}_` : ""}JSON` : undefined;

export const getCurrentTournamentChannel = (tournamentId) => `${tournamentId}_CURRENTEVENT_JSON`;
export const getCompetitorEventStatisticsChannel = (tournamentId, rscEvent, competitorId) =>
    `${tournamentId}_STATISTICS_${rscEvent}_COMPETITOR_${competitorId}_JSON`;

export const getBinariesChannel = () => "BINARIES";
export const getEventBinariesChannel = (tournamentId: string) => {
    if (tournamentId) {
        return `BINARIES_${tournamentId}`;
    }
    return undefined;
};

export const getCompStrucChannel = (tournamentId) => `${tournamentId}_COMPSTRUCT_JSON`;

export const getFinalRankingChannel = (tournamentId, eventRsc) => {
    if (tournamentId && eventRsc) {
        return `${tournamentId}_FINALRANKING_${eventRsc}_JSON`;
    }
    return undefined;
};

export const getCompDetailsChannel = (tournamentId, rsc) => `${tournamentId}_COMPDETAILS_${rsc}_JSON`;

export const getStandingDataChannel = (tournamentId) => `${tournamentId}_STANDINGDATA_JSON`;

export const getEntriesChannel = (tournamentId) => `${tournamentId}_ENTRIES_JSON`;

export const getCombatChannel = (tournamentId, rsc) => `${tournamentId}_COMBAT_${rsc}_JSON`;

export const getGameChannel = (tournamentId, rsc) => `${tournamentId}_GAME_${rsc}_JSON`;

export const getStandingsChannel = (tournamentId, rsc) => `${tournamentId}_STANDINGS_${rsc}_JSON`;

export const getGameActionChannel = (tournamentId, rsc, period) => `${tournamentId}_GAMEACTIONS_${rsc}_${period}_JSON`;

export const getRacquetChannel = (tournamentId, rsc) => `${tournamentId}_RACQUET_${rsc}_JSON`;

export const getJudgementChannel = (tournamentId, rsc) => `${tournamentId}_JUDGEMENT_${rsc}_JSON`;

export const getIntermediateChannel = (tournamentId: string, rsc: string, int: string) =>
    `${tournamentId}_INTERMEDIATE_${rsc}_${int}_JSON`;

export const getRecordsChannel = (tournamentId: string, recordType: RecordAreaType) =>
    `${tournamentId}_RECORDS_${recordType}_JSON`;
export const getRecordSetClassesChannel = (tournamentId: string) => `${tournamentId}_RECORDSET_CLASSES_JSON`;

export const getSeasonChannelName = (seasonId: string) => `SEASON_${seasonId}_JSON`;
