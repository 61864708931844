<template>
    <component :is="tag" class="rank">{{ irm || rank }}</component>
</template>
<script setup lang="ts">
import { useIrmName } from "@swisstiming/falcon-core";
import { ICompetitorDetail } from "@swisstiming/webtec-kit";
import { computed, PropType } from "vue";

const props = defineProps({
    tag: {
        type: String,
        default: "td"
    },
    competitor: {
        type: Object as PropType<ICompetitorDetail>,
        required: true
    }
});
const irmKey = computed(() => props.competitor.IRM);
const irm = useIrmName(irmKey);
const rank = computed(() => props.competitor.Rank);
</script>
<style></style>
