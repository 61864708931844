import { defineStore } from "pinia";

type LogLevel = "debug" | "info" | "warn" | "error";

export const useWtcLoggingStore = defineStore("WtcLoggingStore", () => {
    const log = (message, level: LogLevel = "debug") => {
        switch (level) {
            case "debug":
                console.log(message);
                break;
            case "info":
                console.info(message);
                break;
            case "warn":
                console.warn(message);
                break;
            case "error":
                console.error(message);
                break;

            default:
                console.log(message);
                break;
        }
    };

    return {
        log
    };
});

export const handleWarn = (message: string) => {
    useWtcLoggingStore().log(message, "warn");
};

export const handleError = (message: string) => {
    useWtcLoggingStore().log(message, "error");
};

export const handleInfo = (message: string) => {
    useWtcLoggingStore().log(message, "info");
};

export const handleDebug = (message: string) => {
    useWtcLoggingStore().log(message, "debug");
};
