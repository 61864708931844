<template>
    <template v-if="channelName">
        <WtcTransition v-if="useTransition && hasChannelData" appear name="slide-down-only-in">
            <slot :channelContent="channelContentValue" name="slot__result" />
        </WtcTransition>
        <slot v-else-if="hasChannelData" :channelContent="channelContentValue" name="slot__result" />

        <slot v-else-if="isNotInitialized" name="slot__not-initialized"> </slot>
        <slot v-else name="slot__place-holder">
            <WtcLoading v-if="!hideLoader"></WtcLoading>
        </slot>
    </template>
</template>
<script lang="ts">
import { cloneDeep } from "lodash-es";
import { computed, defineComponent, inject } from "vue";

import { ChannelStatus } from "../../enums/channel.enums";
import { IChannelMeta } from "../../interfaces/channel";
import { CHANNEL_PROVIDE_SYMBOL, CHANNEL_SETTINGS_SYMBOL } from "../../keys/provide.keys";
import { subscribeChannel, substituteChannel, unsubscribeChannel } from "../../manager/vue-channel.manager";
import WtcTransition from "../appearance/WtcTransition.vue";
import WtcLoading from "./WtcLoading.vue";
export default defineComponent({
    name: "Channel",
    emits: ["update:channel"],
    setup() {
        const hideLoader = inject(CHANNEL_SETTINGS_SYMBOL, false);
        return { hideLoader };
    },
    /**
     * @param {boolean} once - the initial and answered channel-response will be cloned, saved locally and provided to the consumer. Afterwards the channel well be unsubscribed.
     * @param {boolean} update - set from 'false' to 'true' to trigger a snapshot from the current state of the corresponding channel and update 'staticContent', will unsubscribe afterwards
     */
    props: {
        channelName: {
            type: String
        },
        once: {
            type: Boolean,
            default: false
        },
        update: {
            type: Boolean,
            default: false
        },
        useTransition: {
            type: Boolean,
            default: false
        }
    },
    data(): { staticContent?: object; channelValue?: IChannelMeta } {
        return {
            staticContent: undefined,
            channelValue: undefined
        };
    },
    components: {
        WtcLoading,
        WtcTransition
    },
    created() {
        this.channelValue = subscribeChannel(this.channelName);
    },
    unmounted() {
        if (!this.hasStaticContent) {
            unsubscribeChannel(this.channelName);
        }
    },
    watch: {
        once(isOnce) {
            if (!isOnce) {
                // If once ever gets updated, the cloned content needs to be reset, otherwise these old information can still be passed to a subscriber
                this.staticContent = undefined;
            }
        },
        update(bool) {
            if (bool === true && this.hasStaticContent) {
                this.staticContent = undefined;
                this.channelValue = subscribeChannel(this.channelName);
            }
        },
        channelName(newChannelValue, oldChannelValue) {
            this.staticContent = undefined;
            this.channelValue = substituteChannel(newChannelValue, oldChannelValue);
        },
        writeStaticContent: {
            handler(bool) {
                if (bool && this.channelContent !== undefined) {
                    this.staticContent = cloneDeep(this.channelContent);
                    unsubscribeChannel(this.channelName);
                }
            },
            immediate: true
        },
        channelContentValue(newVal) {
            this.$emit("update:channel", newVal);
        }
    },
    computed: {
        writeStaticContent(): boolean {
            return this.isAnswered && this.once && this.staticContent === undefined;
        },
        hasStaticContent(): boolean {
            return this.once && this.staticContent !== undefined;
        },
        hasChannelData(): boolean {
            return (this.isAnswered || this.hasStaticContent) && this.channelContentValue;
        },
        channelContent() {
            return this.channelValue?.content;
        },
        channelContentValue() {
            if (this.once) {
                return this.staticContent;
            } else {
                return this.channelContent;
            }
        },
        channelStatus(): ChannelStatus {
            return this.channelValue?.status ?? ChannelStatus.PENDING;
        },
        isAnswered(): boolean {
            return this.channelStatus === ChannelStatus.ANSWERED;
        },
        isNotInitialized(): boolean {
            return this.channelStatus === ChannelStatus.NOT_INITIALIZED;
        }
    },
    provide() {
        return {
            [CHANNEL_PROVIDE_SYMBOL]: computed(() => this.channelContentValue)
        };
    }
});
</script>
