import { isEmpty, toNumber } from "lodash-es";

export const createPopperTooltipThemeSelector = (prefix: string) => `${prefix}-tooltip`;
export const createComponentPrefixSelector = (prefix: string) => `${prefix}__`;

export const boldString = (str: string, searchTerm: string): string => {
    const reg = new RegExp(`(${searchTerm?.replaceAll(" ", "|")})`, "gi");
    return str.replace(reg, "<b>$1</b>");
};

export const isZeroTimeString = (value: string) => {
    const normalizedValueAsNumber = toNumber(value?.replaceAll(":", "."));
    return !isNaN(normalizedValueAsNumber) && normalizedValueAsNumber === 0;
};

/**
 * search-term filter, checks every substring of the 'stringToSearch' if it starts with any of the substrings of the 'searchInput'
 * existence of substring matters, not the order
 */
export const areSubstringsStartingWith = (searchTerm: string, stringToSearch: string) => {
    if (!isEmpty(searchTerm) && !isEmpty(stringToSearch)) {
        return searchTerm
            ?.toLowerCase()
            ?.split(" ")
            ?.filter((inputSubStr) => inputSubStr.length > 0)
            ?.every((inputSubStr) =>
                stringToSearch.split(" ").some((searchSubStr: string) => searchSubStr.startsWith(inputSubStr))
            );
    }

    return false;
};

export const getCombinedValue = (valueA = "", valueB = "", delimiter = " "): string =>
    valueA && valueB ? valueA + delimiter + valueB : ((valueA || valueB) ?? "");

export const getDefaultValue = (value, fallback = 0): string => (value !== undefined ? value : fallback);

export const addLeadingZero = (val: string | number, places = 2) => val?.toString()?.padStart(places, "0");

export const getWrappedValue = (value: string, wrapperStart: string, wrapperEnd: string) =>
    value ? `${wrapperStart}${value}${wrapperEnd}` : value;

export const addBrackets = (text: string | number, bracketType: "(" | "[" | "{" = "(") => {
    if (!text) {
        return "";
    }
    switch (bracketType) {
        case "(":
            return `(${text})`;
        case "[":
            return `[${text}]`;
        case "{":
            return `\{${text}}`;
        default:
            return text;
    }
};
