import "./styles/styles.scss";

import { falconPlugin } from "@swisstiming/falcon-core";
import { SportCodes } from "@swisstiming/webtec-kit";
import { createApp } from "vue";

import App from "./App.vue";

createApp(App)
    .use(falconPlugin, {
        title: "Luge Web Results",
        storeOptions: {
            sportCode: SportCodes.LUGE,
            initOptions: {
                withCurrentEvent: true,
                withCisConfig: true,
                withSeason: true
            }
        }
    })
    .mount("#app");
