<template>
    <header>
        <div class="bg-brand flex flex-row items-center p-2">
            <WtcImage class="w-a max-h-[70px] p-1" :url="logo"></WtcImage>
            <LugEventDescription class="ml-3" :eventDescription="currentChannel"></LugEventDescription>
        </div>
        <div
            v-if="currentChannel.StartRecord?.Time || currentChannel.TrackRecord?.Time"
            class="max-laptop:px-3 max-tablet:gap-1 max-w-container mx-auto mt-3 flex flex-row gap-5"
        >
            <LugRecord
                v-if="currentChannel.StartRecord?.Time"
                :record="currentChannel.StartRecord"
                :typeName="$getStrByLng().START_RECORD"
            ></LugRecord>
            <LugRecord
                v-if="currentChannel.TrackRecord?.Time"
                :record="currentChannel.TrackRecord"
                :typeName="$getStrByLng().TRACK_RECORD"
            ></LugRecord>
        </div>
    </header>
</template>

<script setup lang="ts">
import { useSledLiveChannel } from "@swisstiming/falcon-core";
import { WtcImage } from "@swisstiming/webtec-kit";

import logo from "../../assets/images/fil-logo.svg";
import LugRecord from "../common/LugRecord.vue";
import LugEventDescription from "./LugEventDescription.vue";

const { currentChannel } = useSledLiveChannel();
</script>
