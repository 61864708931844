<template>
    <div class="falcon-frame w-full">
        <slot v-if="firstConnectEstablished && authorized"></slot>
        <slot v-if="!firstConnectEstablished" name="loading">
            <WtcLoading></WtcLoading>
        </slot>

        <slot v-if="!authorized" name="unauthorized">
            <!-- TODO: SCHAND - Check if the styles are of your likeing. -->
            <FalconPlaceHolder :msg="'no-access'"></FalconPlaceHolder>
        </slot>
    </div>
</template>
<script setup lang="ts">
import { useFalconStore } from "@swisstiming/falcon-core";
import { WtcLoading } from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";

import FalconPlaceHolder from "./FalconPlaceHolder.vue";

const { authorized, isConnected } = storeToRefs(useFalconStore());

const firstConnectEstablished = ref(false);

const unWatch = watch(isConnected, (newVal, prevVal) => {
    if (prevVal === false && newVal === true) {
        firstConnectEstablished.value = true;
        unWatch();
    }
});
</script>

<style scoped lang="scss"></style>
