export enum ChannelStatus {
    NOT_REQUESTED = "notRequested",
    PENDING = "pending",
    ANSWERED = "answered",
    FAILED = "failed",
    NOT_INITIALIZED = "notInitalized",
    REMOVED = "removed",
    PARTIAL = "partial"
}

export enum CombatCompTypes {
    JUDGES = "Judges",
    POINTS = "Points",
    TEAM = "TEAM"
}

export enum ResultStatus {
    FINISHED = "ResultStatus-7-"
}

export enum ScheduleStatus {
    Planned = "Event-1-",
    Scheduled = "Event-2-",
    GettingReady = "Event-3-",
    Running = "Event-4-",
    Break = "Event-5-",
    Unofficial = "Event-6-",
    Official = "Event-7-",
    Finished = "Event-8-",
    Delayed = "Event-9-",
    Cancelled = "Event-11-",
    Protested = "Event-12-",
    Postponed = "Event-13-",
    Rescheduled = "Event-14-",
    Interrupted = "Event-15-",
    ComingUp = "Event-50-",
    FinalJudgeCheck = "Event-51-"
}

export enum CommonCompTypes {
    TEAM = "TEAM",
    INDIVIDUAL = "INDIVIDUAL",
    RELAY = "RELAY",
    SINGLES = "SINGLES",
    DOUBLES = "DOUBLES",
    MEDAL = "MEDAL",
    DEMO = "Demo"
}

export enum CommonRoundTypes {
    QUAL = "QUAL"
}

export enum CommonStatTypes {
    NON_EVENT = "NONEVENT"
}

export enum IntermediateTypes {
    PRE_TIMING = "PRETIMING",
    START_CONTROL = "STARTCONTROL",
    FINISH = "FINISH",
    INTERMEDIATE = "INTERMEDIATE",
    CUSTOM = "CUSTOM",
    SECTOR = "SECTOR"
}

export enum PeriodTypes {
    PERIOD = "PERIOD",
    OVERTIME = "OVERTIME"
}

export enum GameActionVariants {
    START_PERIOD = "STARTP",
    END_PERIOD = "ENDP",
    START_GAME = "STARTG",
    END_GAME = "ENDG",
    SUBSTITUTION = "SUBST"
}

export enum GameActionCategories {
    ASSIST = "GAME_ACTION_CATEGORIES"
}

export enum GameActionTypes {
    RALLY = "Rally",
    PAIR = "Pair",
    SINGLE = "Single",
    SCORE = "Score"
}

export enum ResultChannelTypes {
    NONE = "none",
    TIMING = "timing",
    COMBAT = "combat",
    GAME = "game",
    RACQUET = "racquet",
    JUDGEMENT = "judgement"
}

export enum MeetingStatus {
    ComingUp = "Meeting-3-",
    Running = "Meeting-4-",
    Break = "Meeting-5-",
    Finished = "Meeting-8-",
    NotActive = "Meeting-20-",
    Offline = "Meeting-21-"
}
