<template>
    <component :is="tag" :class="isBehindPositive ? 'text-content-negative' : 'text-content-positive'">
        {{ formattedValue }}
    </component>
</template>
<script setup lang="ts">
import { computed, PropType } from "vue";

const props = defineProps({
    behind: String,
    tag: {
        type: String,
        default: "td"
    },
    valueFormatter: {
        type: Function as PropType<(behind?: string) => string>
    }
});

const isBehindPositive = computed(() => parseFloat(props.behind) > 0);
const formattedValue = computed(() => props.valueFormatter?.(props.behind) ?? props.behind);
</script>
