<template>
    <WtcTransition appear name="slide-left">
        <div v-if="hasResults" :key="hasResults">
            <div class="max-tablet:flex-wrap flex flex-row gap-3">
                <template v-if="runsOrdered.length > 1">
                    <div
                        v-for="run in runsOrdered"
                        :key="run.Name"
                        class="bg-surface flex h-9 flex-row items-center rounded-lg p-2 text-sm"
                    >
                        <div class="mr-3 text-xs">{{ toOrdinal(run.Number) }}</div>
                        <div class="font-normal_bold flex items-center text-base leading-none">
                            {{ run.Result
                            }}<span class="font-normal_regular ml-1 text-sm font-normal">{{
                                getWrappedValue(run.Rank, "[", "]")
                            }}</span>
                        </div>
                    </div>
                </template>
                <div class="bg-surface flex h-9 flex-row items-center rounded-lg p-2 text-sm">
                    <div class="mr-1 text-xs">{{ $getStrByLng().TOTAL }}</div>
                    <div class="font-normal_bold flex items-center text-base leading-none">
                        {{ competitor.Result
                        }}<span class="font-normal_regular ml-1 text-sm font-normal">{{ totalRank }}</span>
                    </div>
                </div>
                <div v-if="competitor.Behind" class="bg-surface flex h-9 flex-row items-center rounded-lg p-2 text-sm">
                    <div class="mr-1 text-xs">{{ $getStrByLng().BEHIND }}</div>
                    <FalconBehind
                        :behind="competitor.Behind"
                        class="font-normal_bold flex items-center text-base leading-none"
                        tag="div"
                    ></FalconBehind>
                </div>
            </div>
        </div>
    </WtcTransition>
</template>
<script setup lang="ts">
import { FalconBehind, ISledCurrentCompetitor } from "@swisstiming/falcon-core";
import { getWrappedValue, toOrdinal, WtcTransition } from "@swisstiming/webtec-kit";
import { orderBy } from "lodash-es";
import { computed } from "vue";

const props = defineProps<{
    competitor?: ISledCurrentCompetitor;
}>();

const totalRank = computed(() => getWrappedValue(props.competitor.Rank, "[", "]"));
const runsOrdered = computed(() =>
    orderBy(props.competitor.Runs, "ListIndex").filter((run) => run.Number !== undefined)
);
const hasResults = computed(() => props.competitor.Result || props.competitor.Behind);
</script>
