<template>
    <div>
        <div class="font-normal_bold p-2 text-sm">{{ $getStrByLng().SPEED_KMH }}</div>
        <table  class="mb-2">
            <thead>
                <tr>
                    <th>{{ $getStrByLng().RUN }}</th>
                    <th v-for="speed in speedsOrdered" :key="speed.Name">
                        {{ speed.Name }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="round in roundsOrdered" :key="round.UnitName">
                    <td>{{ toOrdinal(round.Number) }}</td>
                    <td v-for="speed in round.Speeds" :key="speed.Name">
                        {{ speed.Result }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script setup lang="ts">
import { ISledCurrentCompetitor } from "@swisstiming/falcon-core";
import { toOrdinal } from "@swisstiming/webtec-kit";
import { orderBy } from "lodash-es";
import { computed } from "vue";

const props = defineProps<{
    competitor: ISledCurrentCompetitor;
}>();

const currentCompetitor = computed(() =>
    props.competitor.IsTeam && props.competitor.CurrentChildId
        ? props.competitor.Children?.[props.competitor.CurrentChildId]
        : props.competitor
);

const speedsOrdered = computed(() => orderBy(currentCompetitor.value.Speeds, "ListIndex"));
const roundsOrdered = computed(() => orderBy(currentCompetitor.value.RoundRes, "ListIndex"));
</script>
<style></style>
