<template>
    <div class="flex">
        <div
            v-if="configEventTitle || seasonEvent?.Header1 || seasonLocation"
            class="mr-5 flex flex-col border-r border-white/60 pr-5"
        >
            <span class="text-2xl uppercase text-white">{{ configEventTitle || seasonEvent?.Header1 }}</span>
            <span class="text-sm leading-none text-white/60">{{ seasonLocation }}</span>
        </div>
        <div class="flex flex-col justify-center text-lg text-white">
            <span class="font-normal_light">{{ eventUnitName }}</span>
            <span class="text-xs text-white/60"
                ><WtcDateTime :date="eventDescription?.StartTime" format="DD.MM.YYYY, HH:mm"></WtcDateTime
            ></span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useFalconStore } from "@swisstiming/falcon-core";
import { getCombinedValue, WtcDateTime } from "@swisstiming/webtec-kit";
import { computed } from "vue";

import { ILugEventDescription } from "../../interfaces/lug.interfaces";

const props = withDefaults(
    defineProps<{
        eventDescription?: ILugEventDescription;
    }>(),
    {
        eventDescription: (): ILugEventDescription => {
            return {};
        }
    }
);

const falconStore = useFalconStore();

const eventUnitName = computed(() =>
    getCombinedValue(props.eventDescription?.EventName, props.eventDescription?.UnitName, " - ")
);

const configEventTitle = computed(() => falconStore.data.misConfigData?.Event?.Title);

const seasonEvent = computed(() => falconStore.data.seasonData?.Events?.[falconStore.config.tournamentId]);
const seasonLocation = computed(() => getCombinedValue(seasonEvent.value?.Header2, seasonEvent.value?.Header3, ", "));
</script>
